<template>
  <div class="data-tools">
    <div class="data-tools__header">
      <molecule-navigation
        :items="navigationItems"
        class="data-tools__navigation"
      ></molecule-navigation>
      <div class="data-tools__search">
        <input
          type="text"
          name="search-input"
          v-model="searchQuery"
          class="data-tools__search-input"
          placeholder="Chercher"
        />
        <atom-icon icon="search" class="data-tools__search-icon"> </atom-icon>
      </div>
    </div>
    <div class="data-tools__section">
      <div class="data-tools__section-header">
        <h2 class="data-tools__section-heading">{{institution}}</h2>
        <div class="data-tools__section-line"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import MoleculeNavigation from "@/components/molecules/MoleculeNavigation.vue";
  import MoleculeArticleCard from "@/components/molecules/MoleculeArticleCard.vue";
  import MoleculeArticlePreview from "@/components/molecules/MoleculeArticlePreview.vue";
  import MoleculeArticleResource from "@/components/molecules/MoleculeArticleResource.vue";
  import {useRoute} from "vue-router";
  const {params:{institution}} = useRoute();
  const navigationItems = [
  {
    name: "Page d'accueil",
    link: { name: "Home" },
  },
  {
    name: "Digitech",
    link: { name: "DataAndTools" },
  },
];
</script>

<style lang="scss" scoped>
.data-tools {
  @apply container mx-auto px-4 pb-9;
  &__header {
    @apply flex flex-col flex-wrap items-start mt-2
    sm:flex-row sm:items-center sm:justify-between
    mb-9;
  }
  &__navigation {
  }
  &__search {
    @apply relative;
    &-input {
      @apply text-sm font-medium leading-5 rounded-md
        border border-[#D1D5DB] w-[318px] p-[10px] pr-[42px];
      &::placeholder {
        @apply text-black text-sm font-medium;
      }
    }
    &-icon {
      @apply w-4 h-4
        absolute right-2 top-1/2 -translate-y-1/2;
    }
  }
  &__section {
    &-header {
      @apply flex items-center gap-4 mb-4;
    }
    &-line {
      @apply h-[1px] bg-gray-100 flex-1;
    }
    &-heading {
      @apply text-lg font-medium
      flex items-center relative
      md:text-xl;
    }
  }
  &__slider {
    @apply mb-3 md:mb-0 order-2
    md:order-1;
    &-item {
      @apply text-left m-2;
    }
  }
  &__aside {
    @apply flex flex-col gap-4 order-1
    md:order-2;
  }
  &__body {
    @apply flex flex-col gap-9;
  }
}
</style>